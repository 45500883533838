import React, { useState, useEffect } from 'react';
import { DataContext } from './DataContext';

const  { REACT_APP_BACKEND_URL } = process.env

export function DataProvider({ children }) {
  const [tags, setTags] = useState([]);
  const [team, setTeam] = useState([]);
  const [demos, setDemos] = useState([]);
  const [demosAvailability, setDemosAvailability] = useState({});

  const fetchData = (endpoint, setData) => {
    fetch(REACT_APP_BACKEND_URL + endpoint)
      .then(response => response.json())
      .then(data => {
        setData(data)
        if (endpoint === 'collection/demos') {
          const availability = {};
        const fetchPromises = data.map(demo => {
          if (demo.demo_url === "") {
            availability[demo.name] = false;
            return Promise.resolve();
          } else {
            return fetch(demo.demo_url)
              .then(response => {
                availability[demo.name] = response.ok;
              })
              .catch(() => {
                availability[demo.name] = false;
              });
          }
        });
        Promise.all(fetchPromises).then(() => setDemosAvailability(availability));
      }
      });
  };

  useEffect(() => {
    fetchData('collection/tags', setTags);
    fetchData('collection/team', setTeam);
    fetchData('collection/demos', setDemos);
  }, []);

  const addEntry = (endpoint, entry) => {
    const setData = endpoint === 'collection/tags' ? setTags : endpoint === 'collection/team' ? setTeam : setDemos;
    setData(prevData => [...prevData, entry]);

    fetch(REACT_APP_BACKEND_URL + endpoint + "/insert", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(entry)
    });
  };

  const updateEntry = (endpoint, updatedEntry) => {
    const setData = endpoint === 'collection/tags' ? setTags : endpoint === 'collection/team' ? setTeam : setDemos;
    setData(prevData => prevData.map(entry => entry._id === updatedEntry._id ? updatedEntry : entry));
    
    const updatedEntryNoId = {...updatedEntry};
    delete updatedEntryNoId._id;
    fetch(REACT_APP_BACKEND_URL + endpoint + `/update/${updatedEntry._id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updatedEntryNoId)
    });
  };

  const deleteEntry = (endpoint, id) => {
    const setData = endpoint === 'collection/tags' ? setTags : endpoint === 'collection/team' ? setTeam : setDemos;
    setData(prevData => prevData.filter(entry => entry._id !== id));

    fetch(REACT_APP_BACKEND_URL + endpoint + `/delete/${id}`, {
      method: 'DELETE'
    });
  };

  return (
    <DataContext.Provider value={{ tags, team, demos, demosAvailability, addEntry, updateEntry, deleteEntry }}>
      {children}
    </DataContext.Provider>
  );
}