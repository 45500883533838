import { useContext, useState } from 'react';
import { Block, Button, Columns, Container, Content, Form, Heading, Icon, Image, Modal } from 'react-bulma-components';

import { AuthContext } from './AuthContext';
import { DataContext } from './DataContext';

export function EditInfo({ props, show, onClose, update }) {
  const { tags, verticals, businessUnits, addEntry, updateEntry } = useContext(DataContext);

  const title = update ? "Edit Demo Information" : "Add New Demo";

  const [name, setName] = useState(props.name);
  const [description, setDescription] = useState(props.description);
  const [icon, setIcon] = useState(props.icon);
  const [portfolio_url, setPortfolioUrl] = useState(props.portfolio_url);
  const [demo_url, setDemoUrl] = useState(props.demo_url);
  const [demoTags, setDemoTags] = useState(props.tags);
  const [image, setImage] = useState(props.image);
  const [year, setYear] = useState(props.year);
  const [demoBusinessUnits, setDemoBusinessUnits] = useState(props.business_units);
  const [demoVerticals, setDemoVerticals] = useState(props.verticals);


  function handleMultiSelectChanges(e, currentSelected, setField) {
    const value = e.target.value;
    const checked = e.target.checked;
    if (currentSelected.includes(value) && !checked) {
      setField(currentSelected.filter(op => op !== value));
    }
    else {
      setField([...currentSelected, value]);
    }
  };

  function saveChanges() {
    if (update) {
      updateEntry(
        'collection/demos',
        {
          "_id": props._id,
          "name": name,
          "description": description,
          "icon": icon,
          "portfolio_url": portfolio_url,
          "demo_url": demo_url,
          "tags": demoTags,
          "image": image,
          "year": year,
          "business_units": demoBusinessUnits,
          "verticals": demoVerticals,
        }
      );
      console.log("Saving changes:", icon, name, description, portfolio_url, demo_url, demoTags);
    }
    else {
      addEntry(
        'collection/demos',
        {
          "name": name,
          "description": description,
          "icon": icon,
          "portfolio_url": portfolio_url,
          "demo_url": demo_url,
          "tags": demoTags,
          "image": image,
          "year": year,
          "business_units": demoBusinessUnits,
          "verticals": demoVerticals,
        }
      );
      console.log("Adding new demo:", icon, name, description, portfolio_url, demo_url, demoTags);
    }
  };

  return (
    <>
      <Modal show={show} onClose={() => onClose(false)}>
        <Modal.Card>
          <Modal.Card.Header showClose>
            <Modal.Card.Title>{title}</Modal.Card.Title>
          </Modal.Card.Header>

          <Modal.Card.Body>
            <Columns>
              <Columns.Column size="half">
                <Form.Field>
                  <Form.Label>Name</Form.Label>
                  <Form.Control>
                    <Form.Input value={name} onChange={e => setName(e.target.value)} />
                  </Form.Control>
                </Form.Field>

                <Form.Field>
                  <Form.Label>Description</Form.Label>
                  <Form.Control>
                    <Form.Textarea value={description} onChange={e => setDescription(e.target.value)} />
                  </Form.Control>
                </Form.Field>

                <Form.Field>
                  <Form.Field>
                    <Form.Label>Image</Form.Label>
                    <Form.Control>
                      <Form.Input value={image} onChange={e => setImage(e.target.value)} />
                    </Form.Control>
                    <Form.Help>Enter the URL of the image.</Form.Help>
                  </Form.Field>
                  {/* <Form.Label>Image Preview</Form.Label> */}
                  <Form.Field>
                    <Image src={image} />
                  </Form.Field>
                </Form.Field>

                <Form.Field>
                  <Form.Label>Portfolio URL</Form.Label>
                  <Form.Control>
                    <Form.Input value={portfolio_url} onChange={e => setPortfolioUrl(e.target.value)} />
                  </Form.Control>
                </Form.Field>

                <Form.Field>
                  <Form.Label>Year</Form.Label>
                  <Form.Control>
                    <Form.Input value={year} onChange={e => setYear(e.target.value)} />
                  </Form.Control>
                </Form.Field>

              </Columns.Column>

              <Columns.Column size="half">

                <Form.Field>
                  <Form.Label>Icon</Form.Label>
                  <Form.Field className='field has-addons'>
                    <Form.Control>
                      <Button isStatic>
                        <Icon key={icon}>
                          <i className={`fas fa-${icon}`} />
                        </Icon>
                      </Button>
                    </Form.Control>
                    <Form.Control>
                      <Form.Input value={icon} onChange={e => setIcon(e.target.value)} />
                    </Form.Control>
                  </Form.Field>
                  <Form.Help>See more <a href='https://fontawesome.com/search?m=free&o=r' target="_blank" rel="noopener noreferrer">Font Awesome Icons</a> available.</Form.Help>
                </Form.Field>

                <Form.Field>
                  <Form.Label>Demo URL</Form.Label>
                  <Form.Control>
                    <Form.Input value={demo_url} onChange={e => setDemoUrl(e.target.value)} />
                  </Form.Control>
                </Form.Field>

                <Form.Field>
                  <Form.Label>Technologies</Form.Label>
                  {tags.map((tag) =>
                    <>
                      <Form.Control>
                        <Form.Checkbox value={tag.name} checked={demoTags.includes(tag.name)} onChange={e => handleMultiSelectChanges(e, demoTags, setDemoTags)}>
                          {tag.name}
                        </Form.Checkbox>
                      </Form.Control>
                    </>
                  )}
                </Form.Field>

                <Form.Field>
                  <Form.Label>Business Units</Form.Label>
                  {businessUnits.map((tag) =>
                    <>
                      <Form.Control>
                        <Form.Checkbox value={tag.name} checked={demoBusinessUnits.includes(tag.name)} onChange={e => handleMultiSelectChanges(e, demoBusinessUnits, setDemoBusinessUnits)}>
                          {tag.name}
                        </Form.Checkbox>
                      </Form.Control>
                    </>
                  )}
                </Form.Field>

                <Form.Field>
                  <Form.Label>Verticals</Form.Label>
                  {verticals.map((tag) =>
                    <>
                      <Form.Control>
                        <Form.Checkbox value={tag.name} checked={demoVerticals.includes(tag.name)} onChange={e => handleMultiSelectChanges(e, demoVerticals, setDemoVerticals)}>
                          {tag.name}
                        </Form.Checkbox>
                      </Form.Control>
                    </>
                  )}
                </Form.Field>



                {/* <Form.Field>
              <Form.Label>Business Units</Form.Label>
              <Form.Control>
                <Form.Select onChange={function noRefCheck(){}}
                    value={props.business_unit} multiple={false} readOnly={edit}
                  >
                  {businessUnits.map((bu) => <option value={bu.name}>{bu.name}</option>)}
                  </Form.Select>
              </Form.Control>
            </Form.Field>
    
            <Form.Label>Developed by</Form.Label>
            <Panel style={{"overflow-y": "auto"}}>
              {team.map((p) =>
                <Panel.Block renderAs='label'>
                  <Form.Control>
                    <Form.Checkbox value={p.name} checked={props.developed_by.includes(p.name)}>
                      {p.name}
                    </Form.Checkbox>
                  </Form.Control>
              </Panel.Block>
              )}
            </Panel>

            <Form.Field>
              <Form.Label>Developed by</Form.Label>
              <Form.Control>
                <Form.Select onChange={function noRefCheck(){}}
                    value={props.developed_by} multiple={true} readOnly={!edit}
                  >
                  {team.map((p) => <option value={p.name}>{p.name}</option>)}
                  </Form.Select>
              </Form.Control>
            </Form.Field> */}
              </Columns.Column>
            </Columns>
          </Modal.Card.Body>

          <Modal.Card.Footer>
            <Button color="info" renderAs='span' onClick={() => saveChanges()}>
              Save
            </Button>
          </Modal.Card.Footer>

        </Modal.Card>
      </Modal>
    </>
  )
}

function RenderInfo({ props }) {
  return (
    <>
      <Container>
        <Columns className='columns'>

          <Columns.Column>
            <figure className='image'>
              <img src={props.image} alt={props.name} style={{ "border-radius": "10%" }} />
            </figure>

          </Columns.Column>

          <Columns.Column>
            <Content>
              <Heading size={5}>{props.name}</Heading>
              <p>{props.description} </p>
              <p><b>Technologies</b></p>
              {props.tags.map((tag) => <li>{tag}</li>)}
            </Content>

          </Columns.Column>

        </Columns>

      </Container>

      {/* <Container mt={2}>
      <Button
        target="_blank"
        rel="noopener noreferrer"
        color={demoAvailable ? "primary" : ""}
        onClick={() => window.open(props.demo_url)}
      >
        <Icon>
          <i className="fas fa-external-link-alt"></i>
        </Icon>
        <span>Go to demo</span>
      </Button>
      </Container> */}

    </>
  );

}

export function DemoInfo({ props }) {
  const { tags, team, demos, demosAvailability, addEntry, updateEntry, deleteEntry } = useContext(DataContext);
  const { user, token, updateUser } = useContext(AuthContext);
  const canEdit = user && user.profile === 'admin';

  const [edit, setEdit] = useState(false);

  function confirmClick() {
    if (window.confirm("Are you sure you want to delete this demo?")) {
      deleteEntry('collection/demos', props._id);
    }
  }

  return (
    <>
      <Columns>
        <Columns.Column size="three-quarter" m={3}>
          {RenderInfo({ props: props })}
        </Columns.Column>

        {canEdit &&
          <Columns.Column size="one-quarter">
            <Button size="small" className='button is-pulled-right' onClick={() => confirmClick()}>
              <Icon>
                <i className="fas fa-trash"></i>
              </Icon>

            </Button>
            <Button size="small" className='button is-pulled-right' onClick={() => setEdit(true)}>
              <Icon>
                <i className="fas fa-pen"></i>
              </Icon>
              <span>Edit</span>

            </Button>
            <Block>
              {EditInfo({ props: props, show: edit, onClose: setEdit, update: true })}
            </Block>
          </Columns.Column>
        }
      </Columns>
    </>
  );
}