import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthContext } from './components/AuthContext.js';
import { DataProvider } from './components/DataProvider.js';
import { NavBar } from './components/NavBar.js';
import { About } from './views/About.js';
import { Demos } from './views/Demos.js';
import { Home } from './views/Home.js';
import { Login } from './views/Login.js';


function App() {
  const { user } = useContext(AuthContext);

  return (
    user ? (
      <DataProvider>
        <NavBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/demos' element={<Demos />} />
          <Route path='/about' element={<About />} />
        </Routes>
      </DataProvider>
    ) : (
      <Login />
    )
  );
}

export default App;