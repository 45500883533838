import { useContext } from 'react';
import { Button, Image, Navbar } from 'react-bulma-components';
import { AuthContext } from './AuthContext';

export function NavBar () {
  const { user, token, updateUser, logout } = useContext(AuthContext);

  function handleBurgerClick() {
    const burger = document.querySelector('.navbar-burger');
    const menu = document.querySelector('.navbar-menu');
    burger.classList.toggle('is-active');
    menu.classList.toggle('is-active');
  }

  if (!user || !token) {
    window.location.href = '';
    return null;
  }

  return (
    <>
    <Navbar role="navigation" aria-label="main navigation" fixed="top" size="large">
      <Navbar.Brand>
        <Navbar.Item href='/'>
          <Image src="https://www.axians.pt/app/uploads/sites/39/2020/12/logo-axians.png" alt="Axians Portugal"/>
        </Navbar.Item>
        <Navbar.Burger onClick={handleBurgerClick} />
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Container>
          <Navbar.Item className='navbar-item is-tab' href='/'>Home</Navbar.Item>
          <Navbar.Item className='navbar-item is-tab' href='/demos'>Demos</Navbar.Item>
          <Navbar.Item className='navbar-item is-tab' href='/about'>About</Navbar.Item>
        </Navbar.Container>
        <Navbar.Container align="right">
          <Navbar.Item>
            <Button onClick={logout} size='small'>Logout</Button>
          </Navbar.Item>
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
    </>
  );
}

// export function NavBar ({pages, setPage}) { 
//     function handleBurgerClick() {
//       const burger = document.querySelector('.navbar-burger');
//       const menu = document.querySelector('.navbar-menu');
//       burger.classList.toggle('is-active');
//       menu.classList.toggle('is-active');
//     }
  
//     return (
//       <>
//         <div class="hero-head">
//           <nav class="navbar is-fixed-top m-3" role="navigation" aria-label="main navigation">
//             <div class="navbar-brand">
//               <a class="navbar-item" href="https://www.axians.pt">
//                 <img src="https://www.axians.pt/app/uploads/sites/39/2020/12/logo-axians.png" alt="Axians Portugal" />
//               </a>
//               <a class="navbar-burger" role="button" aria-label="menu" aria-expanded="false" onClick={handleBurgerClick}>
//                 <span aria-hidden="true"></span>
//                 <span aria-hidden="true"></span>
//                 <span aria-hidden="true"></span>
//                 <span aria-hidden="true"></span>
//               </a>
//             </div>
//             <div class="navbar-menu">
//               <div class="navbar-start">
//                 {pages.map((pageItem) => (
//                 <a class='navbar-item is-tab' onClick={() => setPage(pageItem)}>{pageItem}</a>
//                   ))}
//               </div>
//             </div>
//           </nav>
//         </div>
//       </>
//     );
//   }