import { useContext, useState } from 'react';

import {
  Button,
  Columns,
  Container,
  Form,
  Icon,
  Level,
  Panel
} from 'react-bulma-components';

import { AuthContext } from './AuthContext';
import { DataContext } from './DataContext';
import { DemoInfo, EditInfo } from './Demo';

function renderTag(tag, activeTechTag, handleTagClick) {
  const active = tag === activeTechTag;
  return (
    <Panel.Tabs.Tab active={active} onClick={() => handleTagClick(tag)}>
      {tag}
    </Panel.Tabs.Tab>
  );
}

function renderDemo(demo, isAvailable, searchText, activeDemo, activeTechTag, activeBusinessUnitTag, activeVerticalTag, handleClick) {
  const textColor = isAvailable ? 'primary' : '';

  if (
    (demo.name.toLowerCase().includes(searchText) || searchText === "") &&
    (demo.tags.includes(activeTechTag) || activeTechTag === 'All') &&
    (demo.business_units.includes(activeBusinessUnitTag) || activeBusinessUnitTag === 'All BUs') &&
    (demo.verticals.includes(activeVerticalTag) || activeVerticalTag === 'All Verticals')
  ) {
    return (
      <>
        <Panel.Block
          textColor={textColor}
          onClick={() => handleClick(demo)}
          className='panel-block is-block'
        >
          <Level>

            <Level.Side align="left">
              <Level.Item>
                <Panel.Icon textColor={textColor}>
                  <i className={`fas fa-${demo.icon}`}></i>
                </Panel.Icon>
                {demo.name}
              </Level.Item>
            </Level.Side>

            <Level.Side align="right">
              <Level.Item>

                <Button
                  target="_blank"
                  rel="noopener noreferrer"
                  color={isAvailable ? "primary" : ""}
                  onClick={() => window.open(demo.demo_url)}
                  size="small"
                  // loading={isLoading && demo.demo_url !== "" ? true : false}
                  disabled={demo.demo_url === "" ? true : false}
                >
                  <Icon>
                    <i className="fas fa-external-link-alt"></i>
                  </Icon>
                  <span>Go to demo</span>
                </Button>

                {/* <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => window.open(data.portfolio_url)}
                    size="small"
                  >
                    <span>Go to portfolio</span>
                  </Button> */}
              </Level.Item>
            </Level.Side>

          </Level>
        </Panel.Block>

        {(activeDemo == demo) &&
          (
            <Panel.Block>
              <Container>
                <DemoInfo props={activeDemo} />
              </Container>
            </Panel.Block>
          )
        }
      </>
    );
  }
  else {
    return
  }
}


export function DemosPanel() {
  const { tags, demos, verticals, businessUnits, demosAvailability } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const canEdit = user && user.profile === 'admin';

  const techTags = ["All", ...tags.map(tag => tag.name)];
  const verticalTags = ["All Verticals", ...verticals.map(vertical => vertical.name)];
  const businessUnitTags = ["All BUs", ...businessUnits.map(businessUnit => businessUnit.name)];

  const [searchText, setSearchText] = useState('');
  const [activeDemo, setActiveDemo] = useState(null);
  const [activeTechTag, setActiveTechTag] = useState(techTags[0]);
  const [activeBusinessUnitTag, setActiveBusinessUnitTag] = useState(businessUnitTags[0]);
  const [activeVerticalTag, setActiveVerticalTag] = useState(verticalTags[0]);
  const [addDemo, setAddDemo] = useState(false);
  const newDemo = { 'name': '', 'description': '', 'tags': [], 'icon': '', 'demo_url': '', 'portfolio_url': '', 'image': '', 'business_units': [], 'verticals': [], 'year': '' };

  function handleDataClick(data) {
    if (activeDemo == data) {
      setActiveDemo(null);
    }
    else {
      setActiveDemo(data);
    }
  }

  return (
    <Panel color='primary'>
      <Panel.Header>
        Demos
      </Panel.Header>

      <Panel.Block className='panel-block is-block'>
        <Columns className='columns'>
          <Columns.Column className='column is-two-thirds'>
            <Form.Control>
              <Icon align="left">
                <i className="fas fa-search"></i>
              </Icon>
              <Form.Input
                className='input'
                placeholder="Search"
                type="text"
                onChange={e => setSearchText(e.target.value.toLowerCase())}
              />
            </Form.Control>
          </Columns.Column>

          <Columns.Column className='column is-one-third'>
            <Columns className='columns'>
              <Columns.Column className='column is-half'>
                <Form.Control>
                  <Icon align="left">
                    <i className="fas fa-tags"></i>
                  </Icon>
                  <Form.Select
                    onChange={e => setActiveBusinessUnitTag(e.target.value)}
                    textSize="small"
                    value={activeBusinessUnitTag}
                  >
                    {businessUnitTags.map((tag) =>
                      <option value={tag}>{tag}</option>
                    )}
                  </Form.Select>
                </Form.Control>
              </Columns.Column>

              <Columns.Column className='column is-half'>
                <Form.Control>
                  <Icon align="left">
                    <i className="fas fa-sitemap"></i>
                  </Icon>
                  <Form.Select
                    onChange={e => setActiveVerticalTag(e.target.value)}
                    textSize="small"
                    value={activeVerticalTag}
                  >
                    {verticalTags.map((tag) =>
                      <option value={tag}>{tag}</option>
                    )}
                  </Form.Select>
                </Form.Control>
              </Columns.Column>
            </Columns>

          </Columns.Column>
        </Columns>
      </Panel.Block>

      <Panel.Tabs>
        {techTags.map((tag) => renderTag(tag, activeTechTag, setActiveTechTag))}
      </Panel.Tabs>

      {demos.map((demo) => renderDemo(
        demo, demosAvailability[demo.name], searchText, activeDemo, activeTechTag, activeBusinessUnitTag, activeVerticalTag, handleDataClick,
      )
      )}
      {canEdit &&
        <Panel.Block className='panel-block is-block'>
          <Button
            target="_blank"
            rel="noopener noreferrer"
            size="small"
            onClick={() => setAddDemo(true)}
          >
            <Icon>
              <i className="fas fa-plus"></i>
            </Icon>
            <span>Add a new demo</span>
          </Button>
          <EditInfo props={newDemo} show={addDemo} onClose={setAddDemo} update={false} />
        </Panel.Block>
      }

    </Panel>
  );
}