import { useState, useContext } from 'react';

import { 
  Container,
  Panel,
  Form,
  Icon,
  Level,
  Button,
  Columns 
} from 'react-bulma-components';

import { DemoInfo, EditInfo } from './Demo';
import { DataContext } from './DataContext';

function renderTag (tag, activeTechTag, handleTagClick) {
    const active = tag === activeTechTag;
    return (
      <Panel.Tabs.Tab active={active} onClick={() => handleTagClick(tag)}>
        {tag}
      </Panel.Tabs.Tab>
    );
}

function renderDemo (demo, isAvailable, searchText, activeDemo, activeTechTag, handleClick) {
    const textColor = isAvailable ? 'primary' : '';

    if (
      (demo.name.toLowerCase().includes(searchText) || searchText === "") &&
      (demo.tags.includes(activeTechTag) || activeTechTag === 'All') 
    ) {
        return (
          <>
          <Panel.Block 
            textColor={textColor}
            onClick={() => handleClick(demo)}
            className='panel-block is-block'
          >
            <Level>

              <Level.Side align="left">
                <Level.Item>
                  <Panel.Icon textColor={textColor}>
                    <i className={`fas fa-${demo.icon}`}></i>
                  </Panel.Icon>
                  {demo.name}
                </Level.Item>
              </Level.Side>

              <Level.Side align="right">
                <Level.Item>
                  
                  <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    color={isAvailable ? "primary" : ""}
                    onClick={() => window.open(demo.demo_url)}
                    size="small"
                    // loading={isLoading && demo.demo_url !== "" ? true : false}
                    disabled={demo.demo_url === "" ? true : false}
                  >
                    <Icon>
                      <i className="fas fa-external-link-alt"></i>
                    </Icon>
                    <span>Go to demo</span>
                  </Button>

                  {/* <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => window.open(data.portfolio_url)}
                    size="small"
                  >
                    <span>Go to portfolio</span>
                  </Button> */}
                </Level.Item>
              </Level.Side>

            </Level>
          </Panel.Block>

          { (activeDemo == demo) &&
            (
          <Panel.Block>
            <Container>
              <DemoInfo props={activeDemo}/>
            </Container>
          </Panel.Block>
            )
          }
          </>
        );
    }
    else {
      return
    }
}


export function DemosPanel () {
  const { tags, team, demos, demosAvailability, addEntry, updateEntry, deleteEntry } = useContext(DataContext);

  const techTags = ["All", ...tags.map(tag => tag.name)];

  const [searchText, setSearchText] = useState('');
  const [activeDemo, setActiveDemo] = useState(null);
  const [activeTechTag, setActiveTechTag] = useState(techTags[0]);
  const [addDemo, setAddDemo] = useState(false);
  const newDemo = {'name': '', 'description': '', 'tags': [], 'icon': '', 'demo_url': '', 'portfolio_url': '', 'image': ''};

  function handleDataClick ( data ) {
    if (activeDemo == data) {
      setActiveDemo(null);
    }
    else {
      setActiveDemo(data);
    }
  }

  return (
    <Panel color='primary'>
      <Panel.Header>
        Demos
      </Panel.Header>
      
      <Panel.Block className='panel-block is-block'>
        <Columns className='columns'>
          <Columns.Column className='column is-full'>
            <Form.Control>
              <Icon align="left">
                <i className="fas fa-search"></i>
              </Icon>
              <Form.Input 
                className='input'
                placeholder="Search"
                type="text"
                onChange={e => setSearchText(e.target.value.toLowerCase())}
              />
            </Form.Control>
          </Columns.Column>

          {/* <Columns.Column className='column is-one-quarter'>
            <Form.Control>
              <Icon align="left">
                <i className="fas fa-tags"></i>
              </Icon>
              <Form.Select
                onChange={e => setActiveBusinessUnitTag(e.target.value)}
                textSize="small"
                value={activeBusinessUnitTag}
              >
                {businessUnitTags.map((tag) => 
                  <option value={tag}>{tag}</option>
                )}
              </Form.Select>
            </Form.Control>
          </Columns.Column> */}
        </Columns>
      </Panel.Block>

      <Panel.Tabs>
        {techTags.map((tag) => renderTag(tag, activeTechTag, setActiveTechTag))}
      </Panel.Tabs>
      
      {demos.map((demo) => renderDemo(
        demo, demosAvailability[demo.name], searchText, activeDemo, activeTechTag, handleDataClick,
        )
      )}

      <Panel.Block className='panel-block is-block'>   
        <Button
          target="_blank"
          rel="noopener noreferrer"
          size="small"
          onClick={() => setAddDemo(true)}
        >
          <Icon>
            <i className="fas fa-plus"></i>
          </Icon>
          <span>Add a new demo</span>
        </Button>
        <EditInfo props={newDemo} show={addDemo} onClose={setAddDemo} update={false}/>
      </Panel.Block>

    </Panel>
  );
}