import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from './AuthContext';
import { DataContext } from './DataContext';

const { REACT_APP_BACKEND_URL } = process.env

export function DataProvider({ children }) {
  const [tags, setTags] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);

  const [team, setTeam] = useState([]);

  const [demos, setDemos] = useState([]);
  const [demosAvailability, setDemosAvailability] = useState({});

  const { token } = useContext(AuthContext);

  const fetchData = (endpoint, setData) => {
    fetch(REACT_APP_BACKEND_URL + endpoint,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => response.json())
      .then(data => {
        setData(data)
        if (endpoint === 'collection/demos') {
          const availability = {};
          const fetchPromises = Array.isArray(data) ? data.map(demo => {
            if (demo.demo_url === "") {
              availability[demo.name] = false;
              return Promise.resolve();
            } else {
              return fetch(demo.demo_url)
                .then(response => {
                  availability[demo.name] = response.ok;
                })
                .catch(() => {
                  availability[demo.name] = false;
                });
            }
          }) : [];

          Promise.all(fetchPromises).then(() => setDemosAvailability(availability));
        }
      });
  };

  useEffect(() => {
    fetchData('collection/tags', setTags);
    fetchData('collection/team', setTeam);
    fetchData('collection/demos', setDemos);
    fetchData('collection/verticals', setVerticals);
    fetchData('collection/businessUnits', setBusinessUnits);
  }, []);

  const getSetDataFunction = (endpoint) => {
    switch (endpoint) {
      case 'collection/tags':
        return setTags;
      case 'collection/team':
        return setTeam;
      case 'collection/verticals':
        return setVerticals;
      case 'collection/businessUnits':
        return setBusinessUnits;
      default:
        return setDemos;
    }
  };

  const addEntry = (endpoint, entry) => {
    const setData = getSetDataFunction(endpoint);
    setData(prevData => [...prevData, entry]);

    fetch(REACT_APP_BACKEND_URL + endpoint + "/insert", {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(entry)
    });
  };

  const updateEntry = (endpoint, updatedEntry) => {
    const setData = getSetDataFunction(endpoint);
    setData(prevData => prevData.map(entry => entry._id === updatedEntry._id ? updatedEntry : entry));

    const updatedEntryNoId = { ...updatedEntry };
    delete updatedEntryNoId._id;
    fetch(REACT_APP_BACKEND_URL + endpoint + `/update/${updatedEntry._id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedEntryNoId)
    });
  };

  const deleteEntry = (endpoint, id) => {
    const setData = getSetDataFunction(endpoint);
    setData(prevData => prevData.filter(entry => entry._id !== id));

    fetch(REACT_APP_BACKEND_URL + endpoint + `/delete/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
  };

  return (
    <DataContext.Provider value={{ tags, team, demos, verticals, businessUnits, demosAvailability, addEntry, updateEntry, deleteEntry }}>
      {children}
    </DataContext.Provider>
  );
}