import { Button, Container, Content, Progress, Modal, Form, Card } from 'react-bulma-components';
import { PersonInfo, EditPersonInfo } from '../components/Team';
import { DataContext } from '../components/DataContext';
import { useContext, useState } from 'react';


export function About () {
    const { tags, team, demos, addEntry, updateEntry, deleteEntry } = useContext(DataContext);
    const [editMode, setEditMode] = useState(false);
    const [add, setAdd] = useState(false);

    const sortedTeam = team.sort((a, b) => a.name.localeCompare(b.name));
    return (
        <>
        <Container p={5}>
            <Content mt={6}>
            <h1>About Us</h1>
            <p>Learn more about the Co-Innovation AI and the people behind it.</p>
            </Content>
        {team.length === 0 ? 
            <>
                <h1>Loading...</h1>
                <Progress color="link"/>
            </> : 
            <>
            <Container>
                <div class="grid mt-6 is-col-min-9">
                    {sortedTeam.map(person => (
                        <div class="cell" >
                            <PersonInfo props={person} editMode={editMode}/>
                        </div>
                    ))}
                </div>
            </Container>
            </>
        }
        <Container mt={3}>
        { editMode &&
            (
                <Button onClick={() => setAdd(true)}>Add new team member</Button>
            )
        }
        </Container>
        { add &&
            <EditPersonInfo show={add} onClose={setAdd} update={false}/>
        }
        <Form.Checkbox mt={3} onClick={() => setEditMode(!editMode)}>
            Edit
        </Form.Checkbox>
        </Container>
        </>
    );
}
