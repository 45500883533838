import {Card, Content, Icon, Heading, Button, Modal, Form} from 'react-bulma-components';
import { DataContext } from './DataContext';
import { useContext } from 'react';

export function PersonInfo (props) {
    return (
        <>
        <Card>
            <Card.Content>
                <Card.Image size={128} src={props.image} rounded={true} style={{"height": "11em", }}/>
            </Card.Content>

            <Card.Content>
                <Heading size={5} style={{"height": "35px"}}>{props.name}</Heading>

                <Content>
                    <p class="subtitle is-6" style={{"height": "35px"}}>{props.job}</p>
                    <p class="subtitle is-6" style={{"height": "35px"}}>{props.background}</p>
                </Content>

            </Card.Content>

            <Card.Footer>
                <Card.Footer.Item>
                <Icon>
                    <a href={`mailto:${props.email}`}>
                        <i className="fas fa-envelope"></i>
                    </a>
                </Icon>
                </Card.Footer.Item>
                
                <Card.Footer.Item>
                <Icon>
                    <a href={props.linkedin}>
                        <i class="fab fa-linkedin"></i>
                    </a>
                </Icon>
                </Card.Footer.Item>
            </Card.Footer>
            
            </Card>
        </>
    );
}

export function EditTeam ({show, onClose}) {
    const { tags, team, demos, demosAvailability, addEntry, updateEntry, deleteEntry } = useContext(DataContext);
    return (
        <Modal show={show} onClose={() => onClose(false)}>
            <Modal.Card>
                <Modal.Card.Header showClose>
                    <Modal.Card.Title>Edit Team</Modal.Card.Title>
                </Modal.Card.Header>
                <Modal.Card.Body>
                    <p>Coming soon...</p>
                </Modal.Card.Body>
                <Modal.Card.Footer>
                    <Button color="info" renderAs='span'>
                        Save
                    </Button>
                </Modal.Card.Footer>
            </Modal.Card>
        </Modal>
    );
}