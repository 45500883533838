import 'bulma/css/bulma.min.css';
import React, { useContext, useState } from 'react';

import { AuthContext } from '../components/AuthContext';

const { REACT_APP_BACKEND_URL } = process.env

export const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { user, token, updateUser } = useContext(AuthContext);

    const handleLogin = async (e) => {
        e.preventDefault();
        const formData = new URLSearchParams();
        formData.append('username', username);
        formData.append('password', password);

        try {
            const response = await fetch(REACT_APP_BACKEND_URL + 'token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData.toString(),
            });

            if (!response.ok) {
                console.error('Login failed:', response.json());
                throw new Error('Login failed');
            }

            const data = await response.json();
            const token = data.access_token;

            const userResponse = await fetch(REACT_APP_BACKEND_URL + 'user', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!userResponse.ok) {
                throw new Error('Failed to fetch user information');
            }

            const userData = await userResponse.json();
            updateUser(userData, token);
            console.log('User Information:', userData);
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="container">
            <div className="columns is-centered">
                <div className="column is-one-third">
                    <div className="box">
                        <h1 className="title has-text-centered">Login</h1>

                        <form onSubmit={handleLogin}>
                            <div className="field">
                                <label className="label">Username</label>
                                <div className="control">
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder="Enter your username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Password</label>
                                <div className="control has-icons-right">
                                    <input
                                        className="input"
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Enter your password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <span
                                        className="icon is-small is-right"
                                        role="button"
                                        tabIndex="0"
                                        aria-label={showPassword ? "Hide password" : "Show password"}
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        <i className={`fas fa-eye${showPassword ? "-slash" : ""}`}></i>
                                    </span>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button className="button is-primary is-fullwidth" type="submit">Login</button>
                                </div>
                            </div>
                            {error && <p className="has-text-danger">{error}</p>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};