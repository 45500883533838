import { Content, Container, Heading, Card, Button } from 'react-bulma-components';
import {
  Animator,
  Fade,
  Move,
  ScrollContainer,
  ScrollPage,
  StickyIn,
  batch,
} from "react-scroll-motion";


function Introduction () {
  return (
      <Container>
        <Heading size={1}>Co-Innovation AI</Heading>
        <Heading subtitle size={3}><i>Axians Portugal</i></Heading>
        <Content>
          <p>Welcome to the Co-Innovation AI Website. This is a project that aims to bring together the best of Axians Portugal and the best of the AI community to create innovative solutions to real-world problems.</p>
        </Content>
      </Container>

  );
}

function AreasOfFocus () {
  const areas = [
    {
      title: "Computer Vision",
      description: "Computer Vision is a field of Artificial Intelligence that enables machines to interpret and understand the visual world.",
      icon: "eye",
      link: "#ComputerVision"
    },
    {
      title: "Natural Language Processing",
      description: "Natural Language Processing is a field of Artificial Intelligence that enables machines to understand and generate human language. ",
      icon: "language",
      link: "#NaturalLanguageProcessing"
    }
  ]
  return (
    <Container>
      <Heading size={2}>Areas of Focus</Heading>
      <Content>
        <p>Our team is focused on the following areas:</p>
        <div class="grid">
          {areas.map((area) => 
          <div class="cell is-col-6">
            <AreaCard props={area} />
          </div>
          )}
        </div>
      </Content>
    </Container>
  );
}

function AreaCard ({props}) {
  return (
    <Card>
      <Card.Header.Icon>
        <span class="icon">
          <i class={`fas fa-${props.icon}`}></i>
        </span>
      </Card.Header.Icon>
      <Card.Header.Title>{props.title}</Card.Header.Title>
      <Card.Content>
        <p>{props.description}</p>
      </Card.Content>
      <Card.Footer>
        <Card.Footer.Item>
          <a href={props.link}>See More</a>
        </Card.Footer.Item>
      </Card.Footer>
    </Card>
  );

}

function Portfolio () {
  return (
    <Container>
      <Heading size={2}>Innovation Portfolio</Heading>
      <Content>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.</p>
        <Button>
          <a>See More</a>
        </Button>
      </Content>
    </Container>
  );
}


export function Home () {
    return (
      <>
      <ScrollContainer>
        <ScrollPage>
          <Animator animation={batch(Fade(), Move(), StickyIn(38, 40))}>
            <Introduction />
          </Animator>
        </ScrollPage>


        <ScrollPage>
          <Animator animation={batch(Fade(), Move(), StickyIn(38, 40))}>
            <AreasOfFocus />
          </Animator>
        </ScrollPage>

        <ScrollPage>
          <Animator animation={batch(Fade(), Move(), StickyIn(65, 40))}>
            <Portfolio />
          </Animator>
        </ScrollPage>

      </ScrollContainer>
      </>
    );
  }
