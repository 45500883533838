import { useContext, useState } from 'react';
import { Button, Container, Content, Progress } from 'react-bulma-components';
import { AuthContext } from '../components/AuthContext';
import { DataContext } from '../components/DataContext';
import { EditTeam, PersonInfo } from '../components/Team';


export function About() {
    const { tags, team, demos, addEntry, updateEntry, deleteEntry } = useContext(DataContext);
    const [showModal, setShowModal] = useState(false);
    const { user, token, updateUser } = useContext(AuthContext);
    const canEdit = user && user.profile === 'admin';

    return (
        <>
            <Container p={5}>
                <Content mt={6}>
                    <h1>About Us</h1>
                    <p>Learn more about the Co-Innovation AI and the people behind it.</p>
                </Content>
                {team.length === 0 ?
                    <>
                        <h1>Loading...</h1>
                        <Progress color="link" />
                    </> :
                    <>
                        <Container>
                            <div class="grid mt-6 is-col-min-9">
                                {team.map(person => (
                                    <div class="cell" >
                                        <PersonInfo {...person} />
                                    </div>
                                ))}
                            </div>
                        </Container>
                    </>
                }
                {canEdit &&
                    <Button mt={3} onClick={() => setShowModal(true)}>
                        Edit
                    </Button>
                }
            </Container>
            <EditTeam show={showModal} onClose={setShowModal} />

        </>

    );
}
