import { Routes, Route } from 'react-router-dom';

import { Home } from './views/Home.js';
import { About } from './views/About.js';
import { Demos } from './views/Demos.js';
import { NavBar } from './components/NavBar.js';
import { DataProvider } from './components/DataProvider.js';


function App () {
  return (
    <>
    <DataProvider>
    <NavBar/>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/demos' element={<Demos />} />
      <Route path='/about' element={<About />} />
    </Routes>
    </DataProvider>
    </>
  );
}

export default App;