import React, { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });
    const [token, setToken] = useState(() => {
        return localStorage.getItem('token');
    });

    const updateUser = (userData, authToken) => {
        setUser(userData);
        setToken(authToken);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('token', authToken);
        window.location.href = '/';
    };

    const logout = () => {
        setUser(null);
        setToken(null);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.href = '/';
    };

    useEffect(() => {
        let logoutTimer;
        const resetLogoutTimer = () => {
            if (logoutTimer) clearTimeout(logoutTimer);
            logoutTimer = setTimeout(logout, 30 * 60 * 1000); // 30 minutes of inactivity
        };

        const events = ['click', 'mousemove', 'keydown', 'scroll', 'touchstart'];
        events.forEach(event => window.addEventListener(event, resetLogoutTimer));

        resetLogoutTimer(); // Initialize the timer

        return () => {
            if (logoutTimer) clearTimeout(logoutTimer);
            events.forEach(event => window.removeEventListener(event, resetLogoutTimer));
        };
    }, []);

    return (
        <AuthContext.Provider value={{ user, token, updateUser, logout }}>
            {children}
        </AuthContext.Provider>
    );
};