// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/bulma@1.0.0/css/bulma.min.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.card-content {
    height: 100%;
}

/* .card-footer {
    height: 100%;
} */
/* 
.card-header {
    height: 50%;
} */
`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;;GAEG;AACH;;;GAGG","sourcesContent":["@import \"https://cdn.jsdelivr.net/npm/bulma@1.0.0/css/bulma.min.css\";\n\n.card {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\n.card-content {\n    height: 100%;\n}\n\n/* .card-footer {\n    height: 100%;\n} */\n/* \n.card-header {\n    height: 50%;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
