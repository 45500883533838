import { Card, Content, Icon, Heading, Button, Modal, Form, Image, Columns } from 'react-bulma-components';
import { DataContext } from './DataContext';
import { useContext, useState } from 'react';

export function PersonInfo ({props, editMode=false}) {
    const { tags, team, demos, demosAvailability, addEntry, updateEntry, deleteEntry } = useContext(DataContext);

    const [edit, setEdit] = useState(false);
    function confirmClick() {
        if (window.confirm("Are you sure you want to delete this team member?")) {
          deleteEntry('collection/team', props._id);
        }
      }

    return (
        <>
        <Card>
        {editMode && (
            <Card.Header className="card-header">
                <Card.Header.Title></Card.Header.Title>
                    <Button className="card-header-icon" onClick={() => setEdit(true)}>
                        <Icon>
                            <i className="fas fa-pen"></i>
                        </Icon>
                    </Button>
                    <Button className="card-header-icon" onClick={() => confirmClick()}>
                        <Icon>
                            <i className="fas fa-trash"></i>
                        </Icon>
                    </Button>
            </Card.Header>
        )}

        <Card.Content>
            <Card.Image size={128} src={props.image} rounded={true} style={{"height": "11em", }}/>
        </Card.Content>

        <Card.Content>
            <Heading size={5} style={{"height": "35px"}}>{props.name}</Heading>

            <Content>
                <p class="subtitle is-6" style={{"height": "35px"}}>{props.job}</p>
                <p class="subtitle is-6" style={{"height": "35px"}}>{props.background}</p>
            </Content>

        </Card.Content>

        <Card.Footer>
            <Card.Footer.Item>
            <Icon>
                <a href={`mailto:${props.email}`}>
                    <i className="fas fa-envelope"></i>
                </a>
            </Icon>
            </Card.Footer.Item>
            
            <Card.Footer.Item>
            <Icon>
                <a href={props.linkedin}>
                    <i class="fab fa-linkedin"></i>
                </a>
            </Icon>
            </Card.Footer.Item>
        </Card.Footer>
        
        </Card>

        { edit && <EditPersonInfo props={props} show={edit} onClose={setEdit} update={true}/> }
    </>
);
}

export function EditPersonInfo ({props={'name': '', 'job': '', 'background': '', 'image': '', 'linkedin': '', 'email': ''}, show, onClose, update}) {
    const { tags, team, demos, demosAvailability, addEntry, updateEntry, deleteEntry } = useContext(DataContext);
    const title = update ? "Edit Team Member" : "Add New Team Member";

    const [name, setName] = useState(props.name);
    const [job, setJob] = useState(props.job);
    const [background, setBackground] = useState(props.background);
    const [email, setEmail] = useState(props.email);
    const [linkedin, setLinkedin] = useState(props.linkedin);
    const [image, setImage] = useState(props.image);
    // const [order, setOrder] = useState(props.order);

    function saveChanges () {
        if (update) {
        updateEntry('collection/team', {"_id": props._id, "name": name, "job": job, "background": background, "email": email, "linkedin": linkedin, "image": image});
        console.log("Saving changes:", name, job, background, email, linkedin, image);
        }
        else {
        addEntry('collection/team', {"name": name, "job": job, "background": background, "email": email, "linkedin": linkedin, "image": image,});
        console.log("Adding new team member:", name, job, background, email, linkedin, image);
        }
        onClose(false);
    }

    return (
        <Modal show={show} onClose={() => onClose(false)}>
            <Modal.Card>
                <Modal.Card.Header showClose>
                    <Modal.Card.Title>{title}</Modal.Card.Title>
                </Modal.Card.Header>
                <Modal.Card.Body>
                    <Form.Field>
                        <Form.Label>Name</Form.Label>
                        <Form.Control>
                            <Form.Input value={name} onChange={e => setName(e.target.value)}/>
                        </Form.Control>
                    </Form.Field>

                    <Form.Field>
                        <Form.Label>Job</Form.Label>
                        <Form.Control>
                            <Form.Input name="job" value={job} onChange={e => setJob(e.target.value)}/>
                        </Form.Control>
                    </Form.Field>

                    <Form.Field>
                        <Form.Label>Background</Form.Label>
                        <Form.Control>
                            <Form.Input name="background" value={background} onChange={e => setBackground(e.target.value)}/>
                        </Form.Control>
                    </Form.Field>

                    {/* <Form.Field>
                        <Form.Label>ID</Form.Label>
                        <Form.Control>
                            <Form.Input value={order} onChange={e => setOrder(e.target.value)}/>
                        </Form.Control>
                        <Form.Help>Enter the order in which the team member should be presented.</Form.Help>
                    </Form.Field>   */}

                    <Form.Field>
                        <Form.Label>Linkedin</Form.Label>
                        <Form.Control>
                            <Form.Input name="linkedin" value={linkedin} onChange={e => setLinkedin(e.target.value)}/>
                        </Form.Control>
                    </Form.Field>

                    <Form.Field>
                        <Form.Label>Email</Form.Label>
                        <Form.Control>
                            <Form.Input value={email} onChange={e => setEmail(e.target.value)}/>
                        </Form.Control>
                    </Form.Field>    

                    <Form.Field>
                        <Form.Label>Image</Form.Label>
                        <Form.Control>
                            <Form.Input name="image" value={image} onChange={e => setImage(e.target.value)}/>
                        </Form.Control>
                        <Form.Help>Enter the URL of the image.</Form.Help>
                    </Form.Field>
                    <Form.Field>
                        <Image src={image} />
                    </Form.Field>

                </Modal.Card.Body>

                <Modal.Card.Footer>
                    <Columns>
                        <Columns.Column>
                            <Button color="info" renderAs='span' onClick={() => saveChanges()}>
                                Save
                            </Button>
                        </Columns.Column>

                        {/* <Columns.Column>
                            <Button color="danger" renderAs='span' onClick={() => deleteMember()}>
                                Delete
                            </Button>
                        </Columns.Column> */}
                    </Columns>
                </Modal.Card.Footer>
                
            </Modal.Card>
        </Modal>
    );
}
